<template>
  <div>
    <b-navbar
      toggleable="true"
      type="light"
      variant="light"
      fixed="top"
      class="bg-white"
    >
      <a
        href="#home"
        @click="toggleCollapse({isHome: true, href: '#home'})"
        v-smooth-scroll="{ offset: -135}"
        class="navbar-brand"
      >
        <strong class="font-italic">present<span class="text-gray">past</span></strong>
      </a>
      <navbar-burger-menu
        :collapse="isCollapsed"
        @collapse-update="updateCollapse"
      />  
      <b-collapse
        id="nav-collapse"
        is-nav
      >
        <b-navbar-nav>
          <li class="nav-item text-uppercase">
            <a
              v-smooth-scroll="{ offset: -135}"
              @click="toggleCollapse({href: '#angebote'})"
              href="#angebote"
              class="nav-link"
            >Angebote</a>
          </li>
          <li class="nav-item text-uppercase">
            <a
              v-smooth-scroll="{ offset: -135}"
              @click="toggleCollapse({href: '#workshops'})"
              href="#workshops"
              class="nav-link"
            >Workshops</a>
          </li>
          <li class="nav-item text-uppercase">
            <a
              v-smooth-scroll="{ offset: -135}"
              @click="toggleCollapse({href: '#person'})"
              href="#person"
              class="nav-link"
            >Zu meiner Person</a>
          </li>
          <li class="nav-item text-uppercase">
            <a
              v-smooth-scroll="{ offset: -135}"
              @click="toggleCollapse({href: '#referenzen'})"
              href="#referenzen"
              class="nav-link"
            >Referenzen</a>
          </li>
          <li class="nav-item text-uppercase">
            <a
              v-smooth-scroll="{ offset: -135}"
              @click="toggleCollapse({href: '#kontakt'})"
              href="#kontakt"
              class="nav-link"
            >Kontakt</a>
          </li>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import NavbarBurgerMenu from "./NavbarBurgerMenu"

export default {
    name: "Navigation",
    components: {
      NavbarBurgerMenu
    },
    data: function () {
      return {
          isCollapsed: true,
          routeHash: '',
          menuItems: [
            {
              path: '/',
              title: ''
            },
            {
              path: '/angebote',
              title: 'Angebote'
            },
            {
              path: '/referenzen',
              title: 'Referenzen'
            },
            {
              path: '/kontakt',
              title: 'Kontakt'
            }
          ] 
          
      }
    },
    computed: {
      title : function() {
        var self = this
        var menuItemTitle = this.menuItems.find(function(menuItem) {
          return menuItem.path == self.$route.path;
        })
        if (menuItemTitle != null && menuItemTitle.hasOwnProperty('title')) {
          if(menuItemTitle.title.length > 0) {
            return 'present past | Recherchen zum Nationalsozialismus in Familie und Gesellschaft. | ' + menuItemTitle.title
          }
        }
        return 'present past | Recherchen zum Nationalsozialismus in Familie und Gesellschaft.'
      }
    },
    methods: {
      toggleCollapse : function(sourceData) {
        // programatically navigate 
        if(sourceData.hasOwnProperty('href')) {
          if(!sourceData.hasOwnProperty('isHome')) {
            this.isCollapsed = !this.isCollapsed
          }
          if(this.$route.path != '/') {
            this.$router.push({ path: '/', hash: sourceData.href })
          }
        }
        if(sourceData.hasOwnProperty('isHome') && this.isCollapsed == false) {
          this.isCollapsed = !this.isCollapsed
          this.triggerCollapse()
        }
      },
      updateCollapse : function(val) {
        this.isCollapsed = val
        this.$root.$emit('bv::toggle::collapse', 'nav-collapse')
      },
      triggerCollapse: function() {
        // eslint-disable-next-line no-console
        this.$root.$emit('bv::toggle::collapse', 'nav-collapse')
      },
    },
    mounted() {
      this.$root.$on('bv::collapse::state', () => {
      //   // eslint-disable-next-line no-console
      //   console.log('collapseId:', collapseId)
      //   // eslint-disable-next-line no-console
      //   console.log('isJustShown:', isJustShown)
      })
    },
    watch: {
      '$route' (to) {
        // eslint-disable-next-line no-console
        console.log('to:', to);
        this.routeHash = to.hash;
        document.title = this.title
      }
    },
    created: function() {
      this.routeHash = this.$route.hash
      document.title = this.title
    }
  }
</script>

<style scoped>
.navbar-brand {
  font-size: 1.5rem;
}
</style>