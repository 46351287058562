<template>
  <section id="angebote">
    <h2 class="text-center">
      Angebote
    </h2>
    <p>
      Gerne berate ich Sie in einem Erstgespräch zu Möglichkeiten und Kosten der Recherche. Der Umfang orientiert sich an eventuell vorhandenem Vorwissen, vor allem aber an Ihren individuellen Wünschen. Das Leistungsspektrum reicht von einer Erstrecherche bis hin zum Verfassen einer Biografie oder etwa einer Firmengeschichte.       <a
        v-smooth-scroll="{ offset: -135}"
        @click="toggleCollapse({href: '#kontakt'})"
        href="#kontakt"
        class=""
      >Ich freue mich auf Ihre Kontaktaufnahme
      </a>.
    </p>
  </section>
</template>

<script>
export default {
    name: "ContentOsteopathie",
    mounted: function() {
      if(this.$route.path && this.$route.path == '/angebote') {
        const element = document.getElementById(this.$route.path.substring(1));
        if(element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }
}
</script>
