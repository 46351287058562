<template>
  <section id="person">
    <h2 class="text-center">
      Zu meiner Person
    </h2>
    <p>
      <span class="font-italic">Present past</span> wird betrieben von Dr. Johannes Spohr. Ich bin Historiker und lebe in Berlin. Mein Geschichtsstudium habe ich 2011 an der Universität Bremen mit einer Magisterarbeit unter dem Titel <span class="font-italic">»Die Wehrmacht in der Geschichtswissenschaft von den 1950er Jahren bis in die Gegenwart«</span> abgeschlossen. Promoviert wurde ich 2020 an der Universität Hamburg zur späten Phase deutscher Besatzung in der Zentralukraine während des Zweiten Weltkrieges. 2021 erschien meine Dissertation im Metropol-Verlag (Titel: <span class="font-italic">»Die Ukraine 1943/44. Loyalitäten und Gewalt im Kontext der Kriegswende«</span>). Immer wieder beschäftigt mich in diesem Zusammenhang auch das Fortwirken des Nationalsozialismus bis in die Gegenwart. Dazu erschien beispielsweise: <span class="font-italic">»Die Ukraine 1943/44. Nationalsozialistische Gewalt und ihre Vergegenwärtigung in der bundesrepublikanischen Öffentlichkeit«, in: Alexandra Klei/Katrin Stoll (Hrsg.): Leerstelle(n)?
        Der deutsche Vernichtungskrieg 1941–1944 und die Vergegenwärtigungen des Geschehens nach 1989, Berlin 2019.</span>
      <br>
      Mit dem Nationalsozialismus in der eigenen Familie habe ich mich über einen langen Zeitraum beschäftigt und 2016 einige Gedanken dazu verschriftlicht: <span class="font-italic">»Ball gegen die Auffahrt: Zwischen Goethe, Jünger und OKH-Treffen« (Buchbeitrag in: Oliver von Wrochem (Hrsg.): Nationalsozialistische Täterschaften. Nachwirkungen in Gesellschaft und Familie, erschienen im Metropol Verlag, Hamburg 2016).</span> Über meine Motivation für familienbiografische Recherchen können Sie in diesem Interview mehr erfahren: <br>
      »Nur wer sich erinnert, kann sich emanzipieren«<br>
      <a
        href="https://reflections.news/de/nur-wer-sich-erinnert-kann-sich-emanzipieren/"
        target="_blank"
      >https://reflections.news/de/nur-wer-sich-erinnert-kann-sich-emanzipieren/</a>
    </p>
    <p>
      Weiterhin in einer Sendung des Deutschlandfunks:<br>
      <a
        href="https://www.deutschlandfunk.de/klarheit-ja-erloesung-nein-was-es-bedeutet-einen-ns-taeter.724.de.html?dram:article_id=451850"
        target="_blank"
      >https://www.deutschlandfunk.de/klarheit-ja-erloesung-nein-was-es-bedeutet-einen-ns-taeter.724.de.html?dram:article_id=451850</a>
    </p>
    <p>
      Der Journalist Jan Pfaff hat in der <span class="font-italic">taz</span> über die Tätigkeiten von <span class="font-italic">present past</span> berichtet:<br>
      <a
        href="https://taz.de/NS-Verbrechen-in-der-Ukraine/!5913858/"
        target="_blank"
      >https://taz.de/NS-Verbrechen-in-der-Ukraine/!5913858/</a>
    </p>
    <p>
      Im Rahmen eines <span class="font-italic">taz Talks</span> habe ich zudem mit ihm über diese Themen gesprochen:<br>
      <a
        href="https://www.youtube.com/watch?v=-rVuPJCtKW4"
        target="_blank"
      >https://www.youtube.com/watch?v=-rVuPJCtKW4</a>
    </p>
    <p>
      Ein Webinar zur NS-Familienforschung des Magazins <span class="font-italic">Chrismon</span> ist ebenfalls online nachzusehen:<br>
      <a
        href="https://chrismon.evangelisch.de/video/webinar-ns-vergangenheit-der-eigenen-familie-recherchieren-53646"
        target="_blank"
      >https://chrismon.evangelisch.de/video/webinar-ns-vergangenheit-der-eigenen-familie-recherchieren-53646</a>
    </p>
    <p>
      Ein Interview zu <span class="font-italic">present past</span> in der Tageszeitung <span class="font-italic">nd</span>:<br>
      <a
        href="https://www.nd-aktuell.de/artikel/1146515.ns-vergangenheit-ist-nicht-vergangen.html"
        target="_blank"
      >https://www.nd-aktuell.de/artikel/1146515.ns-vergangenheit-ist-nicht-vergangen.html</a>
    </p>
    <p>
      Etwas beitragen durfte ich zu einer Folge des empfehlenswerten Podcast »Gestern ist jetzt« für Familiengeschichte im Nationalsozialismus:
      <a
        href="http://gesternistjetzt.de/johannes-spohr/"
        target="_blank"
      >http://gesternistjetzt.de/johannes-spohr/</a>
    </p>
    <p>
      Auf <span class="font-italic">RBB Kultur</span> habe ich mit Alexandra Senfft und Carla Spangenberg über die politischen Dimensionen der NS-Familiengeschichten gesprochen:<br>
      <a
        href="https://www.ardaudiothek.de/episode/der-zweite-gedanke/die-wannsee-konferenz-80-jahre-spaeter-2-3-der-taeter-in-meiner-familie/rbbkultur/96504028/"
        target="_blank"
      >https://www.ardaudiothek.de/episode/der-zweite-gedanke/die-wannsee-konferenz-80-jahre-spaeter-2-3-der-taeter-in-meiner-familie/rbbkultur/96504028/</a>
    </p><p>
      In der <span class="font-italic">Berliner Zeitung</span> wurde über meine Workshops berichtet:<br>
      <a
        href="https://www.berliner-zeitung.de/kultur-vergnuegen/anleitung-zum-wuehlen-in-der-nazi-kiste-li.294263"
        target="_blank"
      >https://www.berliner-zeitung.de/kultur-vergnuegen/anleitung-zum-wuehlen-in-der-nazi-kiste-li.294263</a>
    </p>
    Ich bin Vorstandsmitglied des Vereins KONTAKTE-KOНTAKTbI sowie Mitglied des <span class="font-italic">Arbeitskreises für intergenerationelle Folgen des Holocaust, ehem. PAKH e.V.</span>
  </section>
</template>

<script>
export default {
    name: "ContentPerson",
    mounted: function() {
      if(this.$route.path && this.$route.path == '/person') {
        const element = document.getElementById(this.$route.path.substring(1));
        if(element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }
}
</script>
