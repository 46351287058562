<template>
  <div class="content-wrapper mb-4">
    <h2 class="text-center">
      Impressum
    </h2>
    <p>Angaben gemäß §5 Abs.1 TMG</p>
    <h3 class="h5">
      Verantwortliche für diese Website:
    </h3>
    <p>
      Johannes Spohr<br>
      present past<br>
      12043 Berlin<br>
      <a href="mailto:info@present-past.net">info@present-past.net</a>
    </p>
    <h3 class="h5">
      Realisierung und Gestaltung
    </h3>
    <p>
      <a
        href="https://www.kontrollfeld.de"
        target="_blank"
      >www.kontrollfeld.de</a>
    </p>
    <h3 class="h5">
      Haftung für Inhalte
    </h3>
    <p>
      Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die
      Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch
      keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG
      für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
      verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch
      nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
      überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
      Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
      Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon
      unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt
      der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
      von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
      entfernen.
    </p>
    <h3 class="h5">
      Haftung für Links
    </h3>
    <p>
      Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
      Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden
      Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
      Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
      verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung
      auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
      Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche
      Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte
      einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
      Rechtsverletzungen werden wir derartige Links umgehend entfernen.
    </p>
  </div>
</template>

<script>
export default {
  name: "ContentImpressum",
};
</script>

<style scoped>
h3 {
  margin-bottom: 1rem;
}
</style>
