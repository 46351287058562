<template>
  <div class="content-wrapper">
    <h1 class="h2text-center">
      Datenschutz
    </h1>
    <h3 id="anbieterundverantwortlichestelleimsinnedesdatenschutzgesetzes">
      Anbieter und verantwortliche Stelle im Sinne des Datenschutzgesetzes
    </h3>
    <p>
      Max Mustermann<br>
      Strasse 25<br>
      12345 Berlin<br>
      mail@test.de
    </p>
    <h3
      class="h5"
      id="geltungsbereich"
    >
      Geltungsbereich
    </h3>
    <p>Nutzer erhalten mit dieser Datenschutzerklärung Informationen über die Art, den Umfang und Zweck der Erhebung und Verwendung ihrer Daten, die durch den verantwortlichen Anbieter erhoben und verwendet werden. Den rechtlichen Rahmen für den Datenschutz bilden die EU-Datenschutz-Grundverordnung (EU-DSGVO) und das Bundesdatenschutzgesetz (BDSG).</p>
    <h3
      class="h5"
      id="gegenstanddesdatenschutzes"
    >
      Gegenstand des Datenschutzes
    </h3>
    <p>Gegenstand des Datenschutzes sind personenbezogene Daten. Diese sind Einzelangaben über persönliche oder sachliche Verhältnisse einer bestimmten oder bestimmbaren natürlichen Person. Hierunter fallen z.B. Angaben wie Name, Postanschrift, E-Mail-Adresse oder Telefonnummer, aber auch Nutzungsdaten wie Ihre IP-Adresse. Personenbezogene Daten werden von uns nur dann erhoben, genutzt und weiter gegeben, wenn dies gesetzlich erlaubt ist oder Sie als Nutzer in die Datenerhebung einwilligen.</p>
    <h3
      class="h5"
      id="umfangderdatenerhebungundspeicherung"
    >
      Umfang der Datenerhebung und -speicherung
    </h3>
    <p>Für die normale Nutzung unserer Webseite ist es nicht erforderlich, dass Sie personenbezogene Daten angeben. Postanschriften und E-Mail-Adressen, die im Rahmen von Anfragen angegeben werden, werden ausschließlich für die Korrespondenz beziehungsweise den Versand verwendet und nur im Rahmen der gesetzlichen Aufbewahrungspflichten gespeichert. Bei der Kommunikation per E-Mail kann die vollständige Datensicherheit nicht gewährleistet werden, sodass wir Ihnen bei Informationen mit hohem Geheimhaltungsbedürfnis den Postweg empfehlen.</p>
    <h3
      class="h5"
      id="zugriffsdatenserverlogfiles"
    >
      Zugriffsdaten/Server-Logfiles
    </h3>
    <p>Wir – beziehungsweise unser Hosting-Provider – erheben Daten über jeden Zugriff auf unsere Webseite (so genannte Server-Logfiles). Zu diesen Zugriffsdaten gehören:</p>
    <ul>
      <li>Besuchte Webseite</li>
      <li>Uhrzeit zum Zeitpunkt des Zugriffs</li>
      <li>Menge der gesendeten Daten in Byte</li>
      <li>Quelle/Verweis, von welcher/welchem Sie auf die Seite gelangten</li>
      <li>Verwendeter Browser</li>
      <li>Verwendetes Betriebssystem</li>
      <li>Verwendete IP-Adresse</li>
    </ul>
    <p>Unser Hosting-Provider verwendet diese Protokolldaten nur für statistische Auswertungen zum Zweck des Betriebs, der Sicherheit und der Optimierung des Angebotes. Nach Ablauf der gesetzlichen Frist werden diese Daten gelöscht. Eine andere Verwendung oder Weitergabe an Dritte erfolgt nicht.</p>
    <h3
      class="h5"
      id="auskunftsundwiderrufsrecht"
    >
      Auskunfts- und Widerrufsrecht
    </h3>
    <p>Sie erhalten jederzeit ohne Angabe von Gründen kostenfrei Auskunft über Ihre bei uns gespeicherten Daten. Sie können jederzeit Ihre bei uns erhobenen Daten sperren, berichtigen oder löschen lassen. Auch können Sie jederzeit die uns erteilte Einwilligung zur Datenerhebung und Verwendung ohne Angaben von Gründen widerrufen. Wenden Sie sich hierzu bitte an die oben angegebene Kontaktadresse. Wir stehen Ihnen jederzeit gern für weitergehende Fragen zu unseren Hinweisen zum Datenschutz und zur Verarbeitung Ihrer persönlichen Daten zur Verfügung.</p>
    <h3
      class="h5"
      id="beschwerderecht"
    >
      Beschwerderecht
    </h3>
    <p>Sie haben die Möglichkeit, sich mit einer Beschwerde an uns (Kontaktdaten s.o.) oder an die zuständige Datenschutzaufsichtsbehörde zu wenden. Die für uns zuständige Datenschutzaufsichtsbehörde ist:</p>
    <p>
      Berliner Beauftragte für Datenschutz und Informationsfreiheit
      An der Urania 4-10
      10787 Berlin
    </p>
  </div>
</template>

<script>
export default {
    name: "ContentDatenschutz"
}
</script>

<style scoped>
h3 {
  margin-bottom: 1rem;
}
</style>