<template>
  <div class="content-wrapper">
    <ContentHome />
    <ContentAngebote />
    <ContentWorkshops />
    <ContentPerson />
    <ContentReferenzen />
    <ContentKontakt />
  </div>
</template>
<script>
import ContentHome from "./ContentHome"
import ContentAngebote from "./ContentAngebote"
import ContentWorkshops from "./ContentWorkshops"
import ContentPerson from "./ContentPerson"
import ContentReferenzen from "./ContentReferenzen"
import ContentKontakt from "./ContentKontakt"

export default {
    name: "ContentWrapper",
    components: {
        ContentHome,
        ContentAngebote,
        ContentWorkshops,
        ContentPerson,
        ContentReferenzen,
        ContentKontakt
    }
}
</script>

<style>
section {
    margin-bottom: 100px;
}
section:first-of-type, section:last-of-type {
  min-height: 600px;
}

@media (min-width: 992px) {
  section:first-of-type {
    margin-top: 100px;
  }
}
.content-wrapper {
    margin-top: 150px;
}
</style>

