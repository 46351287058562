// see https://www.codeply.com/go/s4w53OFKE5/bootstrap-4-navbar-animated-toggle-hamburger-close
import { watch } from 'fs';

<template>
  <button
    class="navbar-toggler navbar-toggler-right"
    :class="{collapsed : isCollapsed}"
    type="button"
    aria-controls="nav-collapse"
    :aria-expanded="isCollapsed ? 'false' : 'true'"
    data-target="#nav-collapse"
    data-toggle="collapse"
    @click="triggerCollapse()"
  >
    <span />
    <span />
    <span />
  </button>
</template>

<script>
export default {
    name: "NavbarBurgerMenu",
    props: {
        collapse : Boolean
        },
    data: function () {
    return {
        isCollapsed: true
    }
},
    methods: {
        triggerCollapse: function() {
            // eslint-disable-next-line no-console
            // this.$root.$emit('bv::toggle::collapse', 'nav-collapse')
            this.toggleCollapse();
            this.$emit('collapse-update', this.isCollapsed)
        },
        toggleCollapse: function() {
            this.isCollapsed = !this.isCollapsed
        }
    },
    created: function () {
        this.isCollapsed = this.collapse
    },
    watch: {
        collapse: function(val) {
            this.isCollapsed = val
        }
    },
    mounted() {
        this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
        // eslint-disable-next-line no-console
        console.log('collapseId:', collapseId)
        // eslint-disable-next-line no-console
        console.log('isJustShown:', isJustShown)
        })
    }
}
</script>

<style scoped>

.navbar-toggler:focus,
.navbar-toggler:active {
    outline: 0;
}

.navbar-toggler span {
   display: block;
   background-color: #444;
   height: 3px;
   width: 25px;
   margin-top: 4px;
   margin-bottom: 4px;
   transform: rotate(0deg);
   position: relative;
   left: 0;
   opacity: 1;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
   transition: transform .35s ease-in-out;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute;
    left: 12px;
    top: 10px;
    transform: rotate(135deg);
    opacity: 0.9;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px;
    visibility: hidden;
    background-color: transparent;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute;
    left: 12px;
    top: 10px;
    transform: rotate(-135deg);
    opacity: 0.9;
}

.navbar-toggler-right {
    position: absolute;
    right: 1rem;
}

button {
    border: 0;
}
</style>
