<template>
  <section id="kontakt">
    <h2 class="text-center">
      Kontakt
    </h2>
    <div class="row">
      <div class="col">
        <p class="text-center">
          Kontaktieren Sie mich gern.
        </p>
        <ul class="list-unstyled text-center">
          <li>
            <a
              href="mailto:info@present-past.net"
            >
              <font-awesome-icon
                :icon="['fas', 'envelope']"
                class="mr-2"
              /> 
              info@present-past.net</a>
          </li>
          <!-- <li>
            <a
              href="tel:+491234455667"
            >
              <font-awesome-icon
                :icon="['fas', 'phone-alt']"
                class="mr-2"
              /> 
              +49 123 1456789</a>
          </li> -->
        </ul>
      </div>
    </div>
  </section>
</template>

<script>

export default {
    name: "ContentKontakt",
    mounted: function() {
      if(this.$route.path && this.$route.path == '/kontakt') {
        const element = document.getElementById(this.$route.path.substring(1));
        if(element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }
}
</script>

<style scoped>
  .img-map {
    filter: grayscale(1);
  }
</style>
