<template>
  <section id="workshops">
    <h2 class="text-center">
      Workshops
    </h2>
    <p>
      Wer sich auf den Weg der Recherche begibt, ist nicht immer im Besitz umfangreicher Dokumente. Manchmal liefern Erzählungen aus der Familie erste Anhaltspunkte. Einige beginnen die Suche ohne jegliches Vorwissen. Archive und Institutionen bieten heute zahlreiche Möglichkeiten, den eigenen Fragen nachzugehen. Doch wie gelange ich an welche Informationen? Und wie sind die Ergebnisse zu verstehen? Diesen Fragen können die TeilnehmerInnen sich im Workshop mit professioneller Unterstützung widmen.
      Möglich sind kompakte ebenso wie tagesfüllende Formate in unterschiedlicher Gruppengröße.
      <a
        v-smooth-scroll="{ offset: -135}"
        @click="toggleCollapse({href: '#kontakt'})"
        href="#kontakt"
        class=""
      >Kontaktieren Sie mich gerne für weitere Informationen.
      </a>
    </p>
  </section>
</template>

<script>
export default {
    name: "ContentProjekte",
    mounted: function() {
      if(this.$route.path && this.$route.path == '/workshops') {
        const element = document.getElementById(this.$route.path.substring(1));
        if(element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }
}
</script>
