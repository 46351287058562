<template>
  <section id="home">
    <h1 class="text-center">
      present past
    </h1>
    <h2 
      class="h1 text-center d-none d-sm-block"
    >
      Recherchen zum Nationalsozialismus in Familie und Gesellschaft.
    </h2>
    <h2 
      class="h2 text-center d-block d-sm-none"
    >
      Recherchen zum Nationalsozialismus in Familie und Gesellschaft.
    </h2>
    <b-row align-h="center">
      <b-col>
        <div class="mb-3">
          <img
            src="../assets/img/bild_present_past.jpg"
            class="img-fluid"
            alt="Mappe"
          >
        </div>
      </b-col>
    </b-row>
    <hr class="invisible">
    <p class="text-center">
      Der Nationalsozialismus wirkt auf vielfältige Weisen bis in die heutige
      Gesellschaft hinein. Viele fragen sich heutzutage, welche Rollen ihre
      Familienangehörigen, Bekannten, KollegInnen oder Vereinsmitglieder
      während dieser Zeit innehatten. Archive bieten Möglichkeiten zur eigenen
      Recherche, doch kann diese mitunter überfordern: Wie gelange ich an
      welche Informationen? Und wie sind die Ergebnisse zu verstehen? <span class="font-italic">Present past</span>
      möchte dabei unterstützen und ermutigen, sich auf die Suche zu
      begeben. Beratend, recherchierend und kontextualisierend. So kann die
      eigene Zeit für das Wesentliche genutzt und bedacht vorgegangen werden.
    </p>
  </section>
</template>
<script>
export default {
    name: "ContentHome"
}
</script>
