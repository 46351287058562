<template>
  <footer class="row justify-content-center mb-2">
    <router-link
      to="/datenschutz"
      class="ml-3 mr-3"
    >
      Datenschutz
    </router-link>
    <router-link
      to="/impressum"
      class="ml-3 mr-3"
    >
      Impressum
    </router-link>
  </footer>
</template>

<script>
export default {
    name: "Footer"
}
</script>
