<template>
  <div id="app">
    <b-container>
      <Navigation />
      <router-view />
      <Footer />
    </b-container>
  </div>
</template>

<script>
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";


export default {
    name: 'App',
    components: {
        Navigation,
        Footer
    }
}
</script>

<style>
    #app {

    }
    /* https://stackoverflow.com/questions/4086107/fixed-page-header-overlaps-in-page-anchors/28824157#28824157 */
    :target::before {
      content: "";
      display: block;
      height: 100px; /* fixed header height*/
      margin: -100px 0 0; /* negative fixed header height */
    }

    
</style>
