<template>
  <section id="referenzen">
    <h2 class="text-center">
      Referenzen
    </h2>
    <ul class="list-group list-group-flush">
      <li class="list-group-item">
        DFG-Projekt <I>»Gemeinschaftsfremde« und »Staatsfeinde«: Intergenerationale Handlungs- und Erinnerungsstrukturen in Familien stigmatisierter NS-Opfer in Österreich und Deutschland</I> (Auftrag)
      </li>
      <li class="list-group-item">
        Initiative Gedenkort für die Opfer der NS-»Lebensraum«-Politik  (Beratung)
      </li>
      <li class="list-group-item">
        »BruchStücke ’45 – von NS-Gewalt, Befreiungen und Umbrüchen in Brandenburg«, Stiftung Brandenburgische Gedenkstätten, Einzelausstellung Mahn- und Gedenkstätte Ravensbrück (Beratung)
      </li>
      <li class="list-group-item">
        <a
          href="https://www.weitererzaehlen.at/"
          target="_blank"
        >www.weitererzaehlen.at</a>  (Auftrag)
      </li>
      <li class="list-group-item">
        Anne Frank Zentrum e.V. (Textauftrag)
      </li>
      <li class="list-group-item">
        Böse Déjà-vus | Performance (Beratung und Mitwirken)
      </li>
      <li class="list-group-item">
        Deutsch-ukrainisches Ausstellungsprojekt über die »vergessenen NS-Opfer von Vinnycja« (Workshop und wissenschaftliche Beratung)
      </li>
      <li class="list-group-item">
        Weiterdenken, Heinrich Böll Stiftung Sachsen (Beratung und Auftrag)
      </li>
      <li class="list-group-item">
        Stadt Lingen (Auftrag)
      </li>
      <li class="list-group-item">
        Dokumentarfilm »Wollongong Bob«, SAM Content, Australien (Auftrag)
      </li>
      <li class="list-group-item">
        Gregory Aimaro, Indiana University, M.A. Student REEI Russian East European Institute
      </li>
      <li class="list-group-item">
        Dániel Béres, unabhängiger Filmemacher (Beratung)
      </li>
      <li class="list-group-item">
        Diverse private Aufträge
      </li>
    </ul>
    <h4 class="mt-5">
      Veranstaltungen und Workshops
    </h4>
    Topographie des Terrors (Berlin), Schirn Kunsthalle Frankfurt, NGO We Change, Deutsche Jugend in Europa, Berliner Ensemble, Bildungsstätte Bredbeck, Erinnern für die Zukunft e.V. Bremen, kosmotique Dresden, KZ-Gedenkstätte Neuengamme, Naturfreundejugend Berlin und Leipzig, Summer School »Geschichtsvermittlung in und über Osteuropa. Polen und Russland im Vergleich« (Universität Bochum), Gedenk- und Bildungsstätte Haus der Wannseekonferenz, Buchhandlung <I>She said</I>, Bergische Universität Wuppertal, Landesvereinigung Kulturelle Kinder-
    und Jugendbildung Sachsen e.V. (LKJ Sachsen e.V.)., Volkshochschule Bad Homburg
  </section>
</template>

<script>
export default {
    name: "ContentReferenzen",
    mounted: function() {
      if(this.$route.path && this.$route.path == '/referenzen') {
        const element = document.getElementById(this.$route.path.substring(1));
        if(element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }
}
</script>
