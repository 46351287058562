import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";
import vueSmoothScroll from "vue2-smooth-scroll";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhoneAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ContentWrapper from "./components/ContentWrapper";
import ContentDatenschutz from "./components/ContentDatenschutz";
import ContentImpressum from "./components/ContentImpressum";

import "./scss/custom.scss";

Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(vueSmoothScroll);

library.add(faPhoneAlt, faEnvelope);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

const routes = [
  { path: "/", component: ContentWrapper },
  { path: "/home", component: ContentWrapper },
  { path: "/angebote", component: ContentWrapper },
  { path: "/referenzen", component: ContentWrapper },
  { path: "/kontakt", component: ContentWrapper },
  { path: "/datenschutz", component: ContentDatenschutz },
  { path: "/impressum", component: ContentImpressum },
];

const router = new VueRouter({
  // mode: "history",
  routes: routes,
});

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
